import { render, staticRenderFns } from "./QQauth.vue?vue&type=template&id=2c79b356&scoped=true&"
import script from "./QQauth.vue?vue&type=script&lang=js&"
export * from "./QQauth.vue?vue&type=script&lang=js&"
import style0 from "./QQauth.vue?vue&type=style&index=0&id=2c79b356&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c79b356",
  null
  
)

export default component.exports