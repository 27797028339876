// exposure.js

import 'intersection-observer';
import {postExposeIllust} from "@/api/modules/user";
import state from "@/store/state";


class Exposure {
    dotDataArr = [];
    maxNum;
    _observer;
    _timer = 0;
    added=new Set()

    constructor(maxNum = 90) {
        this.dotDataArr = [];
        this.maxNum = maxNum;
        this._timer = 0;
        this.init();
    }

    init() {
        const self = this;
        // this.dotFromLocalStorage();
        // this.beforeLeaveWebview();
        this._observer = new IntersectionObserver(function (entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    clearTimeout(self._timer);
                    if (entry.time >= 1000) {
                    const exposeIllust={illustId:parseInt(entry.target.attributes['illust_id'].value),createTime:Math.floor(Date.now() / 1000)};
                    if(!self.added.has(exposeIllust.illustId)){
                        self.dotDataArr.push(exposeIllust);
                        self.added.add(exposeIllust.illustId);
                        if (self.dotDataArr.length >= self.maxNum) {
                            self.added.clear();
                            self.dot();
                        } else {
                            self.storeIntoLocalstorage(self.dotDataArr);
                            // if (self.dotDataArr.length > 0) {
                            //     self._timer = window.setTimeout(function () {
                            //         self.dot();
                            //     }, 2000);
                            // }
                        }
                    }
                    //self._observer.unobserve(entry.target);

                    }
                }
            });
        }, {
            root: null,
            rootMargin: "0px",
            threshold: 0.98,
            time: 1000,
        });

    }

    add(entry) {
        this._observer && this._observer.observe(entry.el);
    }

    dot() {
        const dotDataArr = this.dotDataArr.splice(0, this.maxNum);
        // post 请求，你需要根据实际情况添加网络请求的代码
        // ...
        if (state.user.id) {
            postExposeIllust(state.user.id, dotDataArr);
        }
        this.storeIntoLocalstorage(this.dotDataArr);
    }

    storeIntoLocalstorage(dotDataArr) {
        // 存储到 localStorage，根据实际需求自行定义
        window.localStorage.setItem('dotDataArr', JSON.stringify(dotDataArr));
    }

    dotFromLocalStorage() {
        const ctmsStr = window.localStorage.getItem('dotDataArr');
        if (ctmsStr) {
            const ctms = JSON.parse(ctmsStr);
            this.dotDataArr.push(...ctms);
            this.dot();
        }
    }

    beforeLeaveWebview() {
        // 留空，根据实际情况实现
        this.dot();
    }
}



export default Exposure;
